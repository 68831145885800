import { SetContainerStyle } from '../../Functions/SetContainerStyle';
import { SetStyle } from '../../Functions/SetStyle';
import { Props } from '../../props';
import { Button, ButtonGroup } from '../styles';

export function AlignmentSwitches({ block, edit }: Props) {
  if (!block || !edit) return null;
  const setStyle = SetContainerStyle({ block, edit });

  return (
    <ButtonGroup>
      <Button
        onClick={() =>
          setStyle({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
          })
        }
        selected={[undefined, 'flex-start'].includes(
          block.containerStyle?.justifyContent,
        )}
        type="button"
      >
        L
      </Button>
      <Button
        onClick={() =>
          setStyle({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          })
        }
        selected={block.containerStyle?.justifyContent === 'center'}
        type="button"
      >
        C
      </Button>
      <Button
        onClick={() =>
          setStyle({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          })
        }
        selected={block.containerStyle?.justifyContent === 'flex-end'}
        type="button"
      >
        R
      </Button>
    </ButtonGroup>
  );
}
