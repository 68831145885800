import styled from 'styled-components';

interface Props {
  checked?: boolean;
}

export const Container = styled.div<any>`
  cursor: pointer;

  input {
    opacity: 0;
    position: fixed;
  }

  transition: all 0.3s ease-in-out;

  font-family: Nanum Gothic;
  font-weight: 900;

  ${({ focused }) =>
    focused &&
    `
    transform: scale(1.15, 1.15);
    box-shadow: 0px 0px 15px #00000024;

  `}
`;

export const Rectangle = styled.div<any>`
  width: 60px;
  height: 30px;

  cursor: pointer;
  border-radius: 50px;

  position: relative;

  background-color: ${({ theme }) => theme.checkbox?.background};
  border: 1px solid #ccc;
`;

export const Square = styled.div<Props>`
  width: 30px;
  height: 30px;

  transition: all 0.3s ease-in-out;
  border-radius: 50%;

  position: absolute;

  background-color: ${({ theme }) => theme.contrast.primary.d1};

  ${({ checked, theme }) =>
    checked &&
    `
  transform: translateX(100%);
  background-color: ${theme.colors.secondary};
`}
`;

export const Offset = styled.div<any>`
  margin-left: ${({ captionBelow }) => (captionBelow ? '0px' : '70px')};
  height: 20px;

  position: absolute;

  margin-top: ${({ captionBelow }) => (captionBelow ? '0px' : '-25px')};

  display: flex;
  align-items: center;

  color: ${({ theme }) => theme.colors.text};
`;
