import { ToggleUnderscore } from '../../Functions/Text/ToggleUnderscore';
import { Props } from '../../props';
import { Button } from '../styles';

export function UnderlineSwitch({ block, edit }: Props) {
  if (!block || !edit) return null;

  return (
    <Button
      onClick={ToggleUnderscore({ block, edit })}
      selected={String(block.style.textDecoration).includes('underline')}
      type="button"
    >
      U
    </Button>
  );
}
