/* eslint-disable max-classes-per-file */

type CSSPropertyEntry = {
  value: number | string;
  unit?: string;
};

class CSSProperty {
  value: number | string;
  unit?: string;

  constructor(value: number | string, unit?: string) {
    this.value = value;
    this.unit = unit;
  }

  toString() {
    return `${this.value}${this.unit}`;
  }
}

class Stylesheet {
  [property: string]: CSSPropertyEntry;

  constructor(css: string) {
    css
      .replace(/\r/gi, '')
      .split('\n')
      .forEach(entry => {
        if (![entry.includes(':'), entry.includes(';')].every(x => x === true))
          return;

        const prop = entry.split(':')[1].trim();
        const [property, value, unit] = [
          entry.split(':')[0].toLowerCase().trim(),
          Number(prop.match(/[0-9.]+/g)![0]) || prop,
          prop
            .match(/[^0-9;.]+/g)![0]
            .toLowerCase()
            .trim(),
        ];

        this[property] = new CSSProperty(value, unit);
      });
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  /* @ts-ignore */
  toString() {
    /* eslint-disable-next-line */
    return Object.entries<any>(this).reduce(
      (prev, [property, { value, unit }]: [string, CSSPropertyEntry]) =>
        `${prev}${property}: ${value}${unit || ''};\n`,
      '',
    );
  }
}

export const stylesheet = (css: TemplateStringsArray) =>
  new Stylesheet(css.join('\n'));
