import { HTMLAttributes, SetStateAction } from 'react';
import styled from 'styled-components';

import { ChevronDoubleLeft } from '@styled-icons/fluentui-system-regular';

type Props = {
  side?: 'left' | 'right';
  open: boolean;
} & React.DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const Container = styled.div<{ left?: boolean; open: boolean }>`
  height: 100px;
  width: 20px;

  border-right: 3px solid ${({ theme }) => theme.contrast.primary.l0};

  transition: all 0.3s ease-in-out, transform 0s ease-in-out;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  ${({ open, left }) =>
    !open &&
    `
      margin-${!left ? 'right' : 'left'}: -60px;
    `}

  ${({ open, left }) =>
    !open
      ? `transform: rotate(${!left ? '180deg' : '0deg'});`
      : `transform: rotate(${!left ? '0deg' : '180deg'});`}

  > * {
    min-width: 30px;
    max-width: 30px;

    color: ${({ theme }) => theme.contrast.primary.d3};
  }

  &:hover {
    width: 100px;
    height: 100%;

    background: ${({
      theme: {
        contrast: { primary },
      },
    }) =>
      `radial-gradient(circle at right, ${primary.l5} 0, ${primary.l6}, ${primary.l4}00 100%)`};

    ${({ open, left }) =>
      !open
        ? !left
          ? 'margin-left: 300px;'
          : 'margin-right: 220px'
        : !left
        ? 'margin-left: 200px;'
        : 'margin-right: 120px;'}
  }

  padding: 20px;
`;

export function Slider({ side = 'left', open, onClick }: Props) {
  const Icon = side === 'left' ? ChevronDoubleLeft : ChevronDoubleLeft;

  return (
    <Container open={open} onClick={onClick} left={side === 'right'}>
      <Icon />
    </Container>
  );
}
