import styled from 'styled-components';

export const Container = styled.div<{ open?: boolean }>`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  color: ${({ theme }) => theme.contrast.text[3]};

  cursor: pointer;

  span {
    margin: 0 5px;
  }

  &:hover {
    color: ${({ theme }) => theme.contrast.text[0]};
  }

  &,
  * {
    transition: all 0.3s ease-in-out;
  }

  ${({ open }) =>
    open &&
    `
      .arrow {
        transform: rotate(180deg);
      }
    `}
`;

export const OptionContainer = styled.div`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: space-between;

  margin: 10px;

  color: ${({ theme }) => theme.contrast.text[3]};

  cursor: pointer;

  span {
    margin: 0 5px;
  }

  &:hover {
    color: ${({ theme }) => theme.contrast.text[0]};
  }

  &,
  * {
    transition: all 0.3s ease-in-out;
  }

  svg {
    margin: 0 5px;
  }
`;
