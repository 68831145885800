import { State } from 'interfaces/State';
import { createContext, Dispatch, SetStateAction, useState } from 'react';

export declare namespace NSLayout {
  export interface Context {
    navigatorOpen: State<boolean>;
    editorOpen: State<boolean>;
  }

  interface Props {
    children: React.ReactNode;
  }
}

export const Context = createContext<NSLayout.Context>({
  navigatorOpen: null!,
  editorOpen: null!,
});

export function Layout({ children }: NSLayout.Props) {
  const navigatorOpen = useState(false);
  const editorOpen = useState(false);

  return (
    <Context.Provider value={{ navigatorOpen, editorOpen }}>
      {children}
    </Context.Provider>
  );
}
