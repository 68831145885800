import { CreateProjectModal } from 'components/Projects/CreateProjectModal';
import { LS, save } from 'helpers';
import { flatten } from 'helpers/flatten';
import { generateBlockId } from 'helpers/id';
import { Block, Text } from 'interfaces/Blocks';
import data from 'data.json';

import { Project, ProjectForm } from 'interfaces/Project';
import { State } from 'interfaces/State';
import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

export declare namespace NSProjects {
  export interface Context {
    project: State<Project | null>;
    projects: Project[];

    blocks: null | Block[];

    showProjectCreator: State<boolean>;

    createProject: (form: ProjectForm) => Project;
    addBlock: () => void;
  }

  interface Props {
    children: React.ReactNode;
  }
}

export const Context = createContext<NSProjects.Context>({
  blocks: [],

  project: [null, null!],
  projects: [],

  showProjectCreator: [false, null!],

  createProject: null!,
  addBlock: null!,
});

export function Projects({ children }: NSProjects.Props) {
  const [projects, setProjectsFn] = useState<Project[]>(data as any);
  const [project, setProjectFn] = useState<Project | null>(null!);

  const blocks = useMemo(() => project && flatten(project.data), [project]);

  const showProjectCreator = useState(false);

  const createProject = useCallback(
    (form: ProjectForm) => {
      const dupe = projects.find(x => x.name === form.name);
      if (dupe) throw new Error('duplicate project');

      const result = {
        id: generateBlockId(blocks),
        ...form,
        data: [
          {
            id: generateBlockId(blocks),
            type: 'text',
            value: 'Hello world',
            style: {},
            props: {},
            children: [],
          } as Text,
        ],
        createdAt: new Date(),
        updatedAt: new Date(),
      };

      setProjectsFn(current => [...current, result]);
      return result;
    },
    [projects],
  );

  const addBlock = useCallback(() => {
    if (!project) throw new Error('no project selected');

    setProjectFn(
      current =>
        current && {
          ...current,
          data: [
            ...current.data,
            {
              id: generateBlockId(blocks),
              type: 'text',
              value: 'Adimo potestas',
              style: {},
              props: {},
              children: [],
            },
          ],
        },
    );
  }, [project, setProjectFn]);

  useEffect(() => {
    if (project)
      setProjectsFn(current => [
        ...current.filter(x => x.id !== project.id),
        project,
      ]);
  }, [project]);

  useEffect(() => {
    save('projects', projects);
  }, [projects]);

  return (
    <Context.Provider
      value={{
        blocks,
        project: [project, setProjectFn],
        projects,
        showProjectCreator,
        createProject,
        addBlock,
      }}
    >
      {children}

      {showProjectCreator[0] && <CreateProjectModal />}
    </Context.Provider>
  );
}
