import { DetailedHTMLProps, HTMLAttributes, useCallback } from 'react';
import { Container } from './styles';

type Props = {
  children: JSX.Element[];
  disabled?: boolean;
} & DetailedHTMLProps<HTMLAttributes<HTMLFormElement>, HTMLFormElement>;

export function Form({
  children,
  disabled = false,
  onScroll,
  onSubmit,
  ref,
  ...props
}: Props) {
  const applyDisabled = useCallback(
    (Children: any): any => {
      if (!Children) return null;

      return Children.$$typeof
        ? {
            ...Children,
            props: {
              disabled,
              ...Children.props,
              children: Children.props.children
                ? applyDisabled(Children.props.children)
                : undefined,
            },
          }
        : (Array.from(Children) as any).map((Child: React.ReactElement) =>
            (Child as any)?.$$typeof
              ? {
                  ...Child,
                  props: Child.props
                    ? {
                        disabled,
                        ...Child.props,
                        children: Child.props.children
                          ? applyDisabled(Child.props.children)
                          : undefined,
                      }
                    : {},
                }
              : Child,
          );
    },
    [disabled],
  );

  return (
    <Container
      {...props}
      onSubmit={e => {
        e.stopPropagation();
        e.preventDefault();
        if (onSubmit) onSubmit(e);
      }}
    >
      {applyDisabled(children)}
    </Container>
  );
}
