import { SetStyle } from '../../Functions/SetStyle';
import { Props } from '../../props';
import { Button, ButtonGroup } from '../styles';

export function TextAlignSwitches({ block, edit }: Props) {
  if (!block || !edit) return null;
  const setStyle = SetStyle({ block, edit });

  return (
    <ButtonGroup>
      <Button
        onClick={() => setStyle({ textAlign: 'left' })}
        selected={[undefined, 'left'].includes(block.style.textAlign)}
        type="button"
      >
        L
      </Button>
      <Button
        onClick={() => setStyle({ textAlign: 'center' })}
        selected={block.style.textAlign === 'center'}
        type="button"
      >
        C
      </Button>
      <Button
        onClick={() => setStyle({ textAlign: 'right' })}
        selected={block.style.textAlign === 'right'}
        type="button"
      >
        R
      </Button>
    </ButtonGroup>
  );
}
