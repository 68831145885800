import { ToggleStrikethrough } from '../../Functions/Text/ToggleStrikethrough';
import { Props } from '../../props';
import { Button } from '../styles';

export function StrikethroughSwitch({ block, edit }: Props) {
  if (!block || !edit) return null;

  return (
    <Button
      onClick={ToggleStrikethrough({ block, edit })}
      selected={String(block.style.textDecoration).includes('line-through')}
      type="button"
    >
      S
    </Button>
  );
}
