import { Context as Blocks } from 'app/Blocks';
import { Input } from 'components/Forms';
import { useExternalClick } from 'hooks';
import { Text as IText, TextField as ITextField } from 'interfaces/Blocks';
import { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Props } from '../Props';
import { ContentContainer } from '../styles';

const Container = styled.div`
  p,
  input {
    width: 100%;
    font: 1em 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
`;

export function TextField({
  block: b,
  render,
  editState: [editing, setEditing],
}: Props) {
  const block = b as ITextField;

  const { blockState, editBlock } = useContext(Blocks);
  const currentBlock = blockState[0] as ITextField;
  const setBlock = blockState[1];

  const dropOut = useExternalClick(() => setEditing(false));

  return (
    <Container ref={dropOut}>
      <ContentContainer>
        <Input
          autoFocus
          style={{ ...block?.style }}
          {...(block?.props || {})}
          ref={null}
          onClick={() => setBlock(b)}
          onChange={e => {
            editBlock({
              ...block,
              props: { ...block.props, value: e.target.value },
            });
          }}
          onDoubleClick={e => {
            e.stopPropagation();
            setEditing(true);
          }}
        />
      </ContentContainer>
    </Container>
  );
}
