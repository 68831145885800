import { Context as Projects } from 'app/Projects';
import { useDragGesture } from 'hooks';
import { Project } from 'interfaces/Project';
import { useContext, useRef, useState } from 'react';
import styled from 'styled-components';

type Props = { children: React.ReactNode };

export const Container = styled.div<{ width: number; project: Project | null }>`
  border: 5px solid ${({ theme }) => theme.contrast.primary.l0};
  border-radius: 10px;

  width: ${({ width }) => width}px;
  height: ${({ project }) => (project ? '90%' : 'auto')};
  max-height: 90%;

  display: flex;
  justify-content: center;
  align-items: center;

  overflow: auto;
`;

export function Border({ children }: Props) {
  const ref = useRef(null!);
  const [size, setSize] = useState(500);
  const [dragOffset, setDragOffset] = useState<null | { x: number; y: number }>(
    null,
  );

  const {
    project: [project],
  } = useContext(Projects);

  useDragGesture(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (dragOffset !== null) {
        const x = (e.pageX - window.innerWidth / 2) * 2;
        const y = (e.pageY - window.innerHeight / 2) * 2;

        setSize(Math.min(Math.max(310, x, y), 0.8 * window.innerWidth));
      }
    },
    () => setDragOffset(null),
  );

  return (
    <Container
      ref={ref}
      width={size}
      project={project}
      onMouseDown={({ pageX, pageY }) => setDragOffset({ x: pageX, y: pageY })}
    >
      {children}
    </Container>
  );
}
