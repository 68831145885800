import GlobalStyle from 'styles/global';
import { defaultTheme } from 'styles/themes';

import { ThemeProvider } from 'styled-components';
import { Watermark } from 'components/Watermark/Watermark';

import { Main } from './Main';

export function UI() {
  return (
    <ThemeProvider theme={defaultTheme}>
      <Main />
      <Watermark />
      <GlobalStyle />
    </ThemeProvider>
  );
}
