import styled from 'styled-components';

export const TinyTriangle = styled.div`
  position: absolute;

  top: -20px;
  left: calc(50% - 20px);
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 20px solid ${({ theme }) => theme.colors.background};
`;

export const Popup = styled.div<{ triangleless?: boolean }>`
  position: absolute;
  z-index: 20;

  padding: 0px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  animation: fadeIn 1s, slideDownFromElement 0.3s;

  background-color: ${({ theme }) => theme.colors.background};
  box-shadow: 2px 5px 15px #00000020;
  border-radius: 10px;
  width: auto;
  height: auto;

  button {
    font-size: unset;
  }

  h1 {
    margin: 0;
    padding: 0;

    font-size: 1em;
    font-weight: 300;
  }

  top: ${({ triangleless }) => (triangleless ? '20px' : '50px')};
  margin-left: auto;
  margin-right: auto;
`;

export const PopupHourInput = styled.input<{ length: number }>`
  border: none;

  outline: none;
  background: none;

  font-size: 1em;
  font-weight: 300;

  margin: 0;
  width: ${({ length }) => length}ch;
  min-width: 5px;
`;

export const Content = styled.div`
  width: 100%;

  max-height: 70%;

  max-height: 300px;
  overflow-x: hidden;
  overflow-y: auto;

  cursor: default;

  option {
    padding-right: 20px;
  }
`;

export const Field = styled.div<{ spread?: boolean; small?: boolean }>`
  width: 100%;

  display: flex;
  align-items: flex-end;
  justify-content: ${({ spread }) => (spread ? 'space-between' : 'flex-start')};

  ${({ small }) =>
    small &&
    `
    font-size: 0.7em;
  `}
`;

export const Bottom = styled.div<{ spread?: boolean }>`
  width: 100%;
  min-height: 30%;
  max-height: 30%;

  display: flex;

  ${({ spread }) =>
    spread &&
    `
    justify-content: space-between;

  `}

  > div {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: flex-end;
  }

  button {
    font-size: 0.8em;
  }
`;
