import styled, { keyframes } from 'styled-components';

export const fade = (colour: string) => keyframes`
  0% { background-color: ${colour}00; }
  100% { background-color: ${colour}84; }
`;

export const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  z-index: inherit;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: all 0.3s ease-in-out;

  background-color: ${({ theme }) => theme.contrast.primary.d5}84;

  animation: ${({ theme }) => fade(theme.contrast.primary.d5)} 0.7s;
  animation-fill-mode: both;
`;
