/* eslint-disable indent */

import './styled.d';
import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: none;
  }
  
  input {
    -webkit-appearance: none;
    -moz-appearance: textfield;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .unselectable {
    &, * {
      -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
    }
  }
  

  body {
    font: 1em Raleway;

    ${({ theme }) =>
      `
        background-color: ${theme.contrast.primary.l6} !important;
        background: ${`linear-gradient(0deg, ${theme.colors.background}, ${theme.colors.background})`};    
    `}

  
    background-size: calc(100vw + 200px) calc(100vh + 200px);
    background-attachment: fixed;
  }
`;
