import { Context as Blocks } from 'app/Blocks';
import { Input } from 'components/Forms';
import { Option, Select } from 'components/Select';
import { useExternalClick } from 'hooks';
import { SelectField as ISelectField, Text as IText } from 'interfaces/Blocks';
import { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Props } from '../Props';
import { ContentContainer } from '../styles';

const Container = styled.div`
  p,
  input {
    width: 100%;
    font: 1em 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
`;

export function SelectField({
  block: b,
  render,
  editState: [editing, setEditing],
}: Props) {
  const block = b as ISelectField;
  const openState = useState(false);

  const {
    blockState: [, setBlock],
    editBlock,
  } = useContext(Blocks);

  const dropOut = useExternalClick(() => setEditing(false));

  return (
    <Container
      ref={dropOut}
      onDoubleClick={e => {
        e.stopPropagation();
        setEditing(true);
      }}
    >
      <ContentContainer>
        <Select
          open={openState}
          style={{ ...block.style }}
          {...(block.props || {})}
          ref={null}
          onClick={() => setBlock(b)}
          onChange={e => {
            editBlock({ ...block, props: { value: e.target.value } });
          }}
        >
          {Object.entries(block.options || {}).map(([key, value]) => (
            <Option value={value}>{key}</Option>
          ))}
        </Select>
      </ContentContainer>
    </Container>
  );
}
