import { generateBlockId } from 'helpers/id';
import { Block } from 'interfaces/Blocks';

export function newBlock(
  blocks: Block[],
  blockInitialiser: Omit<Block, 'id' | 'children'>,
  children: Omit<Block, 'id'>[] = [],
): Block {
  return {
    id: generateBlockId(blocks),
    ...blockInitialiser,
    children: (children || []).map(child => newBlock(blocks, child)),
  } as Block;
}
