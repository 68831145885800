import React from 'react';
import { State } from 'interfaces/State';
import { Slider } from './Slider';

import { Container, Content } from './styles';

type Props = {
  side: 'left' | 'right';
  openState: State<boolean>;
  children: React.ReactNode;
};

export function Aside({ children, side, openState }: Props) {
  const [open, setOpen] = openState;
  return (
    <Container open={open} side={side}>
      <Content>{children}</Content>
      <Slider open={open} side={side} onClick={() => setOpen(!open)} />
    </Container>
  );
}
