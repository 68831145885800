/* eslint-disable @typescript-eslint/no-explicit-any */

import { useCallback } from 'react';

export function useTimedCallback<T extends (...args: any) => any>(
  callback: T,
  time: number,
  dependencies?: Array<any>,
) {
  return useCallback(
    (...params: Parameters<T>): Promise<void> =>
      new Promise((resolve, reject) => {
        try {
          callback(...(params as Iterable<Parameters<T>>));

          setTimeout(() => {
            resolve();
          }, time);
        } catch (err) {
          reject(err);
        }
      }),
    [callback, time, ...(dependencies || [])],
  );
}
