import styled from 'styled-components';

export const Container = styled.div`
  border: 1px solid ${({ theme }) => theme.contrast.primary.l6};
  margin: 10px;
  padding: 10px;

  position: relative;

  cursor: pointer;

  transition: all 0.3s ease-in-out;

  &:hover {
    border: 1px solid #999999;

    > button {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  > button {
    position: absolute;

    width: 20px;
    height: 20px;

    justify-content: center;
    align-items: center;

    display: none;

    > *:nth-child(1) {
      position: absolute;
    }
  }

  > button:nth-child(1) {
    right: -8px;
    top: -8px;

    background-color: ${({ theme }) => theme.colors.secondary};
    color: white;
  }

  > button:nth-last-child(-n + 1) {
    left: calc(50% - 10px);
    right: calc(50% - 10px);
  }
`;

export const ContentContainer = styled.div`
  width: 100%;

  overflow: hidden;
`;

export const PopupOuterContainer = styled.div`
  position: absolute;
  padding: 10px;
  margin: 0;

  bottom: max(auto, 20%);

  background-color: ${({ theme }) => theme.contrast.primary.l7};
  border: 0.1px solid ${({ theme }) => theme.contrast.primary.l2};
  box-shadow: 0px 0px 15px ${({ theme }) => theme.colors.text}24;

  width: auto;
  height: auto;

  max-width: 100%;

  z-index: 20;

  border: none;
  /* filter: blur(2px); */

  &:hover {
    opacity: 1;
    filter: none;
  }

  transition: opacity 0.3s ease-in-out;
`;

export const PopupInnerContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const FontStyleControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  gap: 10px;

  > div {
    display: flex;
  }

  button {
    min-width: 30px;
    min-height: 30px;

    border-radius: 5px;
  }
`;

export const FieldBlockControls = styled.div`
  padding: 20px;
`;
