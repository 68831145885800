import { rebuild } from 'helpers/blocks';
import { generateBlockId } from 'helpers/id';
import { Block } from 'interfaces/Blocks';
import { Project } from 'interfaces/Project';
import { State } from 'interfaces/State';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { newBlock } from './factories/Block';
import { Context as Projects } from './Projects';

export declare namespace NSBlocks {
  export interface Context {
    blockState: State<Block | null>;

    createBlock: (
      parent: Block | Project,
      block: Partial<Block> & { type: Block['type'] },
    ) => Block;
    deleteBlock: (parent: Block) => void;
    editBlock: (update: Partial<Block>) => void;
  }

  interface Props {
    children: React.ReactNode;
  }
}

export const Context = createContext<NSBlocks.Context>({
  blockState: [null, null!],

  createBlock: null!,
  deleteBlock: null!,
  editBlock: null!,
});

export function Blocks({ children }: NSBlocks.Props) {
  const blockState = useState<Block | null>(null);

  const {
    blocks,
    project: [project, setProject],
  } = useContext(Projects);

  const updateProject = (
    callback: (data: {
      blocks: Block[];
      project: Project;
    }) => readonly [Block[], any?],
  ) => {
    if (!blocks || !project) throw new Error('no project selected');

    const [data, result] = callback({ blocks, project });

    setProject(
      current =>
        current && {
          ...current,
          data,
        },
    );

    return result;
  };

  const createBlock = (
    parent: Block | Project,
    shape: Partial<Block> & { type: Block['type'] },
  ) =>
    updateProject(({ blocks, project }) => {
      const child = newBlock(blocks, { ...shape, style: {} });

      return [
        'data' in parent
          ? [...parent.data, child]
          : rebuild(
              project.data,
              block => block.id === parent.id,
              block => ({
                ...block,
                children: [...block.children, child],
              }),
            ),
        child,
      ];
    });

  const deleteBlock = (block: Block | Project) =>
    updateProject(({ project }) => [
      rebuild(
        project.data.filter(({ id }) => id !== block.id),
        parent => !!parent.children.find(child => child.id === block.id),
        parent => ({
          ...parent,
          children: parent.children.filter(child => child.id !== block.id),
        }),
      ),
    ]);

  const editBlock = (update: Partial<Block>) =>
    updateProject(({ project }) => [
      rebuild(
        project.data,
        ({ id }) => update.id === id,
        match => ({ ...(match as any), ...update }),
      ),
    ]);

  useEffect(() => {
    if (blocks && blockState[0])
      blockState[1](blocks.find(block => block.id === blockState[0]!.id)!);
  }, [blocks]);

  return (
    <Context.Provider
      value={{ blockState, createBlock, deleteBlock, editBlock }}
    >
      {children}
    </Context.Provider>
  );
}
