import { BorderSide } from 'interfaces/CSSBorders';
import { useMemo } from 'react';
import { ToggleBorder } from '../../Functions/Border';
import { Props } from '../../props';
import { Button } from '../styles';

export type CapitalisedSide = 'Top' | 'Bottom' | 'Left' | 'Right';

export function BorderSideSwitch({
  block,
  edit,
  side,
}: Props & { side: BorderSide }) {
  if (!block || !edit) return null;

  const correctedSide = useMemo(
    () =>
      `${side[0].toUpperCase()}${side
        .slice(1)
        .toLowerCase()}` as CapitalisedSide,
    [side],
  );

  return (
    <Button
      onClick={ToggleBorder({ block, edit }, side)}
      selected={!!(block.style as any)[`border-${side}`]}
      type="button"
    >
      {correctedSide[0]}
    </Button>
  );
}
