import { Context as Blocks } from 'app/Blocks';
import { Block } from 'interfaces/Blocks';
import { useContext } from 'react';

type Props = {
  children: JSX.Element | JSX.Element[] | null | false;
};

const transform =
  (block: Block, edit: (block: Block) => void) => (child: JSX.Element) =>
    child
      ? {
          ...child,
          props: {
            ...child.props,
            ...(!child.props.className ? { block, edit } : {}),
            children: [
              ...(
                (child.props.children instanceof Array
                  ? child.props.children
                  : [child.props.children]) || []
              ).map(transform(block, edit)),
            ],
          },
        }
      : null;

export function ControlEditorContext({ children }: Props) {
  const {
    blockState: [block],
    editBlock,
  } = useContext(Blocks);

  return (
    <>
      {children && block
        ? children instanceof Array
          ? children.map(transform(block, editBlock))
          : transform(block, editBlock)(children)
        : null}
    </>
  );
}
