import React, {
  useState,
  useRef,
  forwardRef /*useContext*/,
  useEffect,
} from 'react';
// import { Layout } from "controllers";
import { theme } from './json';

import { Container, Label, Input as Element } from './styles';

type Props = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  round?: boolean;
  placeholder?: any;
  red?: boolean;
};

export const Input = forwardRef((props: Props, fref: any) => {
  const aux: any = useRef(null);
  const ref = fref || aux;

  const [labelUp, setLabelUp] = useState(!!props.value || !!props.defaultValue);

  const blurHandler = (e: any) => {
    if (!e.target.value) {
      setLabelUp(false);
    }
    if (props.onBlur) props.onBlur(e);
  };

  if (props.disabled && ref.current) {
    ref.current.blur();
  }

  useEffect(() => {
    if (ref.current?.value && !labelUp) setLabelUp(true);
  });

  return (
    <Container className={String(props.className)}>
      {props.placeholder && !props.round && (
        <Label
          red={props.red}
          up={labelUp}
          onClick={() => ref.current?.focus()}
        >
          {props.placeholder}
        </Label>
      )}

      <Element
        ref={ref}
        red={props.red}
        {...props}
        onFocus={(e: any) => {
          setLabelUp(true);
          if (props.onFocus) props.onFocus(e);
        }}
        onBlur={blurHandler}
        placeholder={(props.round && props.placeholder) || ''}
        className={String(labelUp && 'Filled')}
      />
    </Container>
  );
});
