import React, { forwardRef } from 'react';
import { Props } from './contants';
import { Button as Element } from './styles';

export const Button = forwardRef(
  (
    { children, onClick, propagationless, ...buttonElementProps }: Props,
    fref: React.ForwardedRef<HTMLButtonElement>,
  ) => (
    <Element
      ref={fref}
      type="button"
      onClick={e => {
        if (propagationless) e.stopPropagation();
        if (onClick) onClick(e);
      }}
      {...(buttonElementProps as any)}
    >
      {children}
    </Element>
  ),
);
