import { Context as Blocks } from 'app/Blocks';
import { useContext } from 'react';
import { Container } from '../styles';

export function Text() {
  const {
    blockState: [block],
  } = useContext(Blocks);

  return <Container />;
}
