import styled from 'styled-components';

export const Container = styled.header`
  position: sticky;

  top: 0;

  width: 100vw;
  height: 50px;

  z-index: 10;

  border-bottom: 2px solid ${({ theme }) => theme.contrast.primary.d1};

  box-shadow: 0 30px 40px rgba(0, 0, 0, 0.1) inset;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Space = styled.div`
  width: 200px;

  display: flex;
  justify-content: center;
  align-items: center;

  button {
    > * {
      width: 20px;
    }
  }
`;
