import styled from 'styled-components';
import { Phone } from 'styles/media';

export const Container = styled.div<{ exiting: boolean }>`
  width: auto;
  height: auto;
  min-width: 300px;
  min-height: 300px;
  max-width: 80vw;
  max-height: 80vh;

  ${Phone(`
    max-width: 98vw;
    max-height: 98vh;
  `)}

  position: fixed;

  background-color: ${({ theme }) => theme.colors.background};
  padding: 20px;

  border-radius: 10px;

  animation-fill-mode: both;
  animation: slideUpFromScreen 0.3s;

  ${({ exiting }) =>
    exiting &&
    `
    animation: exitDownFromScreen 0.8s;
  `}
`;
