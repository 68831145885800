import styled from "styled-components";

export const Container = styled.form`
  display: flex;
  flex-direction: column;

  footer {
    position: absolute;
    bottom: 0px;

    width: 100%;

    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }
`;
