/* eslint-disable no-loop-func */
import { Context as Global } from 'app/Global';
import { Block } from 'interfaces/Blocks';
import { useContext } from 'react';

const { ceil, random } = Math;

export function generateBlockId(blocks: Block[] | null) {
  let id: number = null!;

  while (!id || blocks?.find(x => x.id === id))
    id = ceil(random() * 1000000000);

  return id;
}

export function IdState() {
  const { getUID } = useContext(Global);
  return getUID();
}
