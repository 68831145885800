import { createContext } from 'react';

export declare namespace NSSelectContext {
  export interface Context {
    onChange?: (value: any) => void;
    value?: any;
  }

  interface Props {
    value?: any;
    children: React.ReactNode;
    onChange?: (value: any) => void;
  }
}

export const Context = createContext<NSSelectContext.Context>({
  onChange: null!,
  value: null!,
});

export function SelectContext({
  children,
  onChange,
  value,
}: NSSelectContext.Props) {
  return (
    <Context.Provider value={{ onChange, value }}>{children}</Context.Provider>
  );
}
