import { Context as Projects } from 'app/Projects';
import { useContext } from 'react';
import { Border } from './Border';
import { NewElementSelector } from './NewElementSelector';
import { render } from './render';
import { Centralised, Container } from './styles';

export function FormRenderer() {
  const {
    project: [project],
  } = useContext(Projects);

  return (
    <Border>
      <Container onMouseDown={e => e.stopPropagation()}>
        {project ? (
          <>
            {render(project.data)}

            <Centralised>
              <NewElementSelector createOnProject block={null!} />
            </Centralised>
          </>
        ) : (
          <Centralised>
            <i>Select a project above</i>
          </Centralised>
        )}
      </Container>
    </Border>
  );
}
