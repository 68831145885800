import { Select, Option } from 'components/Select';
import { TextField } from 'interfaces/Blocks';
import { useState } from 'react';
import { Props } from '../props';

export function TextInputTypeSelector({ block, edit }: Props) {
  if (!block || !edit) return null;

  const b = block as TextField;
  const openState = useState(false);

  return (
    <div>
      <Select
        open={openState}
        placeholder="Type"
        onChange={type => edit({ ...b, props: { ...b.props, type } })}
        value={b.props?.type}
      >
        <Option value="text">Text</Option>
        <Option value="email">E-mail</Option>
        <Option value="password">Password</Option>
        <Option value="tel">Phone</Option>
        <Option value="date">Date</Option>
        <Option value="time">Time</Option>
        <Option value="color">Colour</Option>
        <Option value="range">Range</Option>
        <Option value="file">File</Option>
      </Select>
    </div>
  );
}
