import styled from 'styled-components';
import { Phone } from 'styles/media';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  overflow: hidden;

  width: 100vw;
  height: 100vh;
`;

export const View = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;

  /* overflow: hidden; */

  width: 100%;
  height: calc(100% - 75px);
`;

export const Content = styled.main`
  width: calc(100% - 600px);
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  z-index: 5;
`;
