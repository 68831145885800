import { Button as ButtonComponent } from 'components/Forms';
import styled from 'styled-components';
import { stylesheet } from 'styles';
import { buttons } from './constants';

export const Button = styled(ButtonComponent)<{ selected?: boolean }>`
  ${buttons}
  border-radius: 5px;

  width: ${buttons.width};
  height: ${buttons.width};

  border: 1px solid ${({ theme }) => theme.contrast.primary.d1};

  background: linear-gradient(
    90deg,
    ${({
      theme: {
        contrast: { primary },
      },
    }) => `${primary.l6}, ${primary.l6}`}
  );

  &:hover {
    background: linear-gradient(
      90deg,
      ${({
        theme: {
          contrast: { primary },
        },
      }) => `${primary.l3}, ${primary.l1}`}
    );
  }

  ${({
    selected,
    theme: {
      contrast: { primary, text },
    },
  }) =>
    selected &&
    `
    background: linear-gradient(90deg, ${`${primary.d2}, ${primary.d3}`});
    color: ${text[5]};
  `}
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;

  width: auto;
  height: auto;

  button {
    margin: 0;
    border-radius: 0;
    border: none;

    width: 30px !important;
    height: 30px !important;
  }

  > button:nth-child(1) {
    border-radius: 5px 0 0 5px;

    border-left: 1px solid;
    border-top: 1px solid;
    border-bottom: 1px solid;
  }

  > button:nth-child(n + 2) {
    border-radius: 0;

    border-top: 1px solid;
    border-bottom: 1px solid;
  }

  > button:nth-last-child(1) {
    border-radius: 0 5px 5px 0;

    border-right: 1px solid;
    border-top: 1px solid;
    border-bottom: 1px solid;
  }

  > * {
    border-color: ${({ theme }) => theme.contrast.primary.d2} !important;
  }
`;
