import { ButtonField } from 'interfaces/Blocks';
import { CSSProperties } from 'react';
import { Params } from './_Params';

export const SetContainerStyle =
  ({ edit, block: { id, containerStyle } }: Params) =>
  (change: { [x in keyof CSSProperties]: CSSProperties[x] }) =>
    edit({
      id,
      containerStyle: {
        ...(containerStyle || {}),
        ...change,
      },
    });
