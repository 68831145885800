import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;

  max-height: 100%;

  border-radius: 10px;

  position: relative;
`;

export const Centralised = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  margin: 10px 0;

  i {
    color: grey;
  }
`;
