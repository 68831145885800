import styled from 'styled-components';
import { Subtract } from '@styled-icons/fluentui-system-regular';
import { useEventListener } from 'hooks';
import { Context as Blocks } from 'app/Blocks';
import { Context as Layout } from 'app/Layout';
import { Button } from 'components/Forms';
import { Block as IBlock } from 'interfaces/Blocks';
import { useContext, useEffect, useId, useState } from 'react';

import { Container } from './styles';

import { components } from './BlockComponents';
import { NewElementSelector } from '../NewElementSelector';
import { PopupEditor } from './PopupEditor';

type Props = {
  block: IBlock;
  render: (blocks: IBlock[]) => JSX.Element[];
};

const Content = styled.div`
  p,
  input {
    width: calc(100% - 5px);
    font: 1em 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }

  input {
    appearance: none;
    border: none;
    background-color: transparent;
  }
`;

export function Block({ block, render }: Props) {
  const id = useId();
  const [editing, setEditing] = useState(false);
  const elementSelectOpenState = useState(false);
  const fireEditingEvent = useEventListener('popup-editor', ({ detail }) => {
    if (detail.id !== id && editing) setEditing(false);
  });

  const {
    editorOpen: [, setEditorOpen],
  } = useContext(Layout);

  const {
    blockState: [, setEditorSelection],

    deleteBlock,
  } = useContext(Blocks);

  const edit = (block: IBlock) => (e: any) => {
    e.stopPropagation();
    setEditorSelection(block);
  };

  const Component = components[block.type];

  useEffect(() => {
    if (editing) fireEditingEvent({ id });
  }, [editing]);

  return (
    <Container
      key={block.id}
      onClick={edit(block)}
      // onDoubleClick={() => setEditorOpen(true)}
    >
      <Button propagationless onClick={() => deleteBlock(block)}>
        <Subtract size={20} />
      </Button>

      <Content>
        {Component && (
          <Component
            block={block}
            render={render}
            editState={[editing, setEditing]}
          />
        )}

        {editing && <PopupEditor />}
      </Content>

      {block.children && render(block.children)}
      <NewElementSelector block={block} />
    </Container>
  );
}
