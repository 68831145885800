import { BrowserRouter as Router } from 'react-router-dom';
import { UI } from 'views';
import { Global } from './Global';
import { Layout } from './Layout';

export function App() {
  return (
    <Router>
      <Layout>
        <UI />
      </Layout>
    </Router>
  );
}
