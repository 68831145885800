import { stylesheet } from 'styles';
import { deviceWidthLimits } from 'styles/media';

export const sheetSize = `${deviceWidthLimits.phone.value}px`;

export const desktopSideModalStyle = stylesheet`
    width: 60%;
`;

export const mobileSideModalStyle = stylesheet`
    width: 100%;
`;
