import { ToggleBold } from '../../Functions/Text/ToggleBold';
import { Props } from '../../props';
import { Button } from '../styles';

export function BoldSwitch({ block, edit }: Props) {
  if (!block || !edit) return null;

  return (
    <Button
      onClick={ToggleBold({ block, edit })}
      selected={(block.style.fontWeight || 300) > 450}
      type="button"
    >
      B
    </Button>
  );
}
