import { CSSProperties } from 'react';
import { Params } from './_Params';

export const SetStyle =
  ({ edit, block: { id, style } }: Params) =>
  (change: { [x in keyof CSSProperties]: CSSProperties[x] }) =>
    edit({
      id,
      style: {
        ...style,
        ...change,
      },
    });
