import { Logo } from 'components/Brands/PYYNE';
import { Container } from './styles';

export function Watermark() {
  return (
    <Container>
      <div>
        <Logo />
        <div>
          <p>
            PYYNE <span className="double-lined">DRAFT</span>
          </p>
        </div>
      </div>
    </Container>
  );
}
