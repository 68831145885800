import { Add } from '@styled-icons/fluentui-system-regular';
import { Context as Projects } from 'app/Projects';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { ProjectSelector } from './ProjectSelector';
import { Container, Space } from './styles';

export function Toolbar() {
  const {
    projects,
    showProjectCreator: [, setProjectCreator],
  } = useContext(Projects);

  return (
    <Container>
      <Space />
      <Space>
        <Link to="new">
          <button type="button" onClick={() => setProjectCreator(true)}>
            <Add />
          </button>
        </Link>

        {projects.length ? <ProjectSelector /> : null}
      </Space>
      <Space />
    </Container>
  );
}
