import { Params } from '../_Params';

export const ToggleStrikethrough =
  ({ edit, block: { id, style } }: Params) =>
  () => {
    const r = String(style.textDecoration || '');
    edit({
      id,
      style: {
        ...style,
        textDecoration: r.includes('line-through')
          ? r.replace('line-through', '').trim()
          : `${r} line-through`.trim(),
      },
    });
  };
