import {
  Add,
  CheckboxChecked,
  Edit,
  List,
  RadioButton,
  TextAdd,
} from '@styled-icons/fluentui-system-regular';
import { Context as Blocks } from 'app/Blocks';
import { Context as Projects } from 'app/Projects';
import { Button } from 'components/Forms';
import { Option, Select } from 'components/Select';
import { Block } from 'interfaces/Blocks';
import { useContext, useEffect, useState } from 'react';

type Props = {
  block: Block;
  createOnProject?: boolean;
};

export function NewElementSelector({ block, createOnProject }: Props) {
  const [open, setOpen] = useState(false);

  const {
    project: [project],
  } = useContext(Projects);

  const {
    blockState: [currentBlock, setCurrentBlock],

    createBlock,
  } = useContext(Blocks);

  useEffect(() => {
    setCurrentBlock(block);
  }, [open]);

  return (
    <Button>
      <Select
        chevronless
        triangleless
        placeholder={
          <Button propagationless onClick={() => setOpen(!open)}>
            <Add size={20} />
          </Button>
        }
        open={[open, setOpen]}
        onChange={type => {
          createBlock(createOnProject ? project! : currentBlock || project!, {
            type,
          });
        }}
      >
        <Option value="text">
          <TextAdd size={20} /> Text
        </Option>
        <Option value="text-field">
          <Edit size={20} /> Field
        </Option>
        <Option value="select-field">
          <List size={20} /> Select
        </Option>
        <Option value="checkbox-field">
          <CheckboxChecked size={20} /> Checkbox
        </Option>
        <Option value="button-field">
          <RadioButton size={20} /> Button
        </Option>
      </Select>
    </Button>
  );
}
