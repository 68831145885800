import { Context as Blocks } from 'app/Blocks';
import { Checkbox, Input } from 'components/Forms';
import { Select } from 'components/Select';
import { useExternalClick } from 'hooks';
import {
  CheckboxField as ICheckboxField,
  Text as IText,
} from 'interfaces/Blocks';
import { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Props } from '../Props';
import { ContentContainer } from '../styles';

const Container = styled.div`
  p,
  input {
    width: 100%;
    font: 1em 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
`;

export function CheckboxField({
  block: b,
  render,
  editState: [editing, setEditing],
}: Props) {
  const block = b as ICheckboxField;

  const {
    blockState: [, setBlock],
    editBlock,
  } = useContext(Blocks);

  const dropOut = useExternalClick(() => setEditing(false));

  return (
    <Container ref={dropOut}>
      <ContentContainer>
        <Checkbox
          toggle={() =>
            editBlock({
              ...block,
              props: { value: !block.props?.value },
            })
          }
          checked={block.props?.value}
          style={{ ...block.style }}
          {...(block.props || {})}
          onClick={(() => setBlock(b)) as any}
          onDoubleClick={(e: any) => {
            e.stopPropagation();
            setEditing(true);
          }}
        >
          {block.text}
        </Checkbox>
      </ContentContainer>
    </Container>
  );
}
