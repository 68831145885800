import { useContext, useState } from 'react';
import { Context as Blocks } from 'app/Blocks';

import { ArrowStepBack } from '@styled-icons/fluentui-system-regular';

import { Button, Input } from 'components/Forms';
import {
  BoldSwitch,
  ControlEditorContext,
  FontColourSwitch,
  FontFamilySelector,
  FontSizeSelector,
  ItalicSwitch,
  PaddingSelector,
  StrikethroughSwitch,
  TextAlignSwitches,
  TextInputTypeSelector,
  UnderlineSwitch,
} from 'components/FormRenderer/Controls';

import {
  PopupOuterContainer,
  PopupInnerContainer,
  FontStyleControls,
  FieldBlockControls,
} from './styles';
import { BorderSideSwitch } from '../Controls/Buttons/Borders/BorderSideSwitch';
import { BorderColourSwitch } from '../Controls/Buttons/Borders';
import { BackgroundColourSwitch } from '../Controls/Buttons/Background/BackgroundColourSwitch';
import { AlignmentSwitches } from '../Controls/Buttons/Container/AlignmentSwitches';

export function PopupEditor() {
  const [view, setView] = useState<null | 'border'>(null);

  const {
    blockState: [block, setBlock],
    editBlock,
  } = useContext(Blocks);

  return (
    <PopupOuterContainer>
      <PopupInnerContainer
        onClick={e => e.stopPropagation()}
        onDoubleClick={e => e.stopPropagation()}
      >
        {!view && (
          <>
            <FontStyleControls>
              <ControlEditorContext>
                <div className="text">
                  <BoldSwitch />
                  <ItalicSwitch />
                  <UnderlineSwitch />
                  <StrikethroughSwitch />
                </div>

                <div className="test">
                  {['text', 'text-field'].includes(String(block?.type)) ? (
                    <TextAlignSwitches />
                  ) : (
                    <AlignmentSwitches />
                  )}
                </div>

                <div className="test">
                  <FontColourSwitch />

                  <BorderColourSwitch />

                  <BackgroundColourSwitch />
                </div>
              </ControlEditorContext>
            </FontStyleControls>

            <FontStyleControls style={{ margin: '20px 0' }}>
              <ControlEditorContext>
                <FontFamilySelector />
                <FontSizeSelector />
                <TextInputTypeSelector />
                <PaddingSelector />
              </ControlEditorContext>

              <Button onClick={() => setView('border')}>Border</Button>
            </FontStyleControls>

            {block?.type === 'text-field' && (
              <FieldBlockControls>
                <Input
                  value={block.props?.placeholder}
                  placeholder="Placeholder"
                  onChange={e =>
                    editBlock({
                      ...block,
                      props: { ...block.props, placeholder: e.target.value },
                    })
                  }
                />

                <Input
                  placeholder="Default value"
                  value={block.props?.defaultValue}
                  onChange={e =>
                    editBlock({
                      ...block,
                      props: { ...block.props, defaultValue: e.target.value },
                    })
                  }
                />
              </FieldBlockControls>
            )}

            {block?.type === 'select-field' && (
              <FieldBlockControls>
                <i>
                  Select fields are under construction. Styles may not apply
                  properly. Adding options is not supported yet.
                </i>
              </FieldBlockControls>
            )}

            {block?.type === 'checkbox-field' && (
              <FieldBlockControls>
                <Input
                  value={block.text}
                  placeholder="Text"
                  onChange={e =>
                    editBlock({
                      ...block,
                      text: e.target.value,
                    })
                  }
                />
              </FieldBlockControls>
            )}

            {block?.type === 'button-field' && (
              <FieldBlockControls>
                <Input
                  value={block.text}
                  placeholder="Text"
                  onChange={e =>
                    editBlock({
                      ...block,
                      text: e.target.value,
                    })
                  }
                />
              </FieldBlockControls>
            )}
          </>
        )}

        {view === 'border' && (
          <FontStyleControls>
            <ControlEditorContext>
              <Button onClick={() => setView(null)}>
                <ArrowStepBack size={20} />
              </Button>
              <BorderSideSwitch side="top" />
              <BorderSideSwitch side="right" />
              <BorderSideSwitch side="bottom" />
              <BorderSideSwitch side="left" />

              <BorderColourSwitch />
            </ControlEditorContext>
          </FontStyleControls>
        )}
      </PopupInnerContainer>
    </PopupOuterContainer>
  );
}
