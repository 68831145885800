/* eslint-disable indent */
import styled, { css, keyframes } from 'styled-components';

import { Desktop, Phone } from 'styles/media';

import {
  mobileSideModalStyle,
  desktopSideModalStyle,
  sheetSize,
} from './constants';

const Slide = (final: number) => keyframes`
  0% { right: -500px; }
  100% { right: ${final}px; }
`;

const reverseSlide = (final: number) => keyframes`
  100% { right: -75%; }
  0% { right: ${final}px; }
`;

export const sheetMargin = 15;
export const sheetInterval = 0.18473;

export const Container = styled.aside<{ exiting: boolean }>`
  height: 100%;

  ${Phone(`
    width: 100%;
  `)}

  ${Desktop(`
    min-width: calc(2 * ${sheetSize});
    max-width: calc(2.5 * ${sheetSize});
  `)}

  display: flex;
  justify-content: flex-end;
  align-items: center;

  position: fixed;
  right: 0;
  top: 0;

  > div {
    height: 100%;
    position: absolute;

    padding: 40px 50px;
    box-shadow: 0px 0px 5px #00000044;
  }

  > div:nth-child(1) {
    width: max(calc(${desktopSideModalStyle.width}), ${sheetSize});

    right: ${sheetMargin * 3}px;
    z-index: 20;

    animation: ${Slide(sheetMargin * 3)} ${sheetInterval}s;
    background-color: ${({ theme }) => theme.contrast.primary.d4}fa;

    ${({ exiting }) =>
      exiting &&
      css`
        animation: ${reverseSlide(sheetMargin * 3)} ${sheetInterval * 3}s;
      `}
  }

  > div:nth-child(2) {
    width: max(calc(${desktopSideModalStyle.width}), ${sheetSize});

    right: ${sheetMargin * 2}px;
    z-index: 30;

    background-color: ${({ theme }) => theme.contrast.primary.l0};
    animation: ${Slide(sheetMargin * 2)}
      ${sheetInterval * 2 - sheetInterval / 3}s;

    ${({ exiting }) =>
      exiting &&
      css`
        animation: ${reverseSlide(sheetMargin * 2)} ${sheetInterval * 2}s;
      `}
  }

  > div:nth-child(3) {
    width: max(calc(${desktopSideModalStyle.width}), ${sheetSize});

    right: ${sheetMargin}px;
    z-index: 40;

    animation: ${Slide(sheetMargin)} ${sheetInterval * 3}s;
    background-color: ${({ theme }) => theme.contrast.primary.l2};

    ${({ exiting }) =>
      exiting &&
      css`
        animation: ${reverseSlide(sheetMargin)} ${sheetInterval}s;
      `}
  }

  > div:nth-child(4) {
    width: max(${desktopSideModalStyle.width}, ${sheetSize});
    right: 0px;
    z-index: 50;

    animation: ${Slide(0)} ${sheetInterval * 4}s;
    background-color: ${({ theme }) => theme.colors.background};

    ${({ exiting }) =>
      exiting &&
      css`
        transition: all 0.3s ease-in-out;
        animation: ${reverseSlide(0)} 0.4s;
        opacity: 0;
      `}
  }

  div {
    animation-fill-mode: both !important;

    ${({ exiting }) =>
      !exiting &&
      Phone(`
      width: ${mobileSideModalStyle.width} !important;
    `)}
  }
`;
