import { Context as Blocks } from 'app/Blocks';
import { Button } from 'components/Forms';
import { useExternalClick } from 'hooks';
import { ButtonField as IButtonField } from 'interfaces/Blocks';
import { useContext } from 'react';
import styled from 'styled-components';
import { Props } from '../Props';
import { ContentContainer } from '../styles';

const Container = styled.div`
  p,
  input {
    width: 100%;
    font: 1em 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
`;

export function ButtonField({
  block: b,
  render,
  editState: [editing, setEditing],
}: Props) {
  const block = b as IButtonField;

  const { blockState } = useContext(Blocks);
  // const currentBlock = blockState[0] as IButtonField;
  const setBlock = blockState[1];

  const dropOut = useExternalClick(() => setEditing(false));

  return (
    <Container ref={dropOut}>
      <ContentContainer style={block.containerStyle || {}}>
        <Button
          style={{ ...block?.style }}
          {...(block?.props || {})}
          ref={null}
          onClick={() => setBlock(b)}
          onDoubleClick={e => {
            e.stopPropagation();
            setEditing(true);
          }}
        >
          {block?.text}
        </Button>
      </ContentContainer>
    </Container>
  );
}
