import { Context as Blocks } from 'app/Blocks';
import { Context as Layout } from 'app/Layout';
import { useContext } from 'react';
import { Aside } from '../Aside';

import { editors } from './Editors';

export function Editor() {
  const { editorOpen } = useContext(Layout);

  const {
    blockState: [selection],
  } = useContext(Blocks);

  const Component = selection ? editors[selection.type as 'text'] : null;

  return (
    <Aside side="right" openState={editorOpen}>
      <div>{selection?.id}</div>
      <div>{Component && <Component />}</div>
    </Aside>
  );
}
