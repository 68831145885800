import { useRef } from 'react';
import styled from 'styled-components';
import { SetStyle } from '../../Functions/SetStyle';
import { Props } from '../../props';

const Square = styled.div<{ colour: string }>`
  border: 1px solid grey;

  width: 15px;
  height: 15px;

  margin: 5px;

  background-color: ${({ colour }) => colour};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  min-width: 30px;
  height: 30px;

  p {
    width: 100%;
    font-size: 1.25em;
    text-align: center;
  }

  input {
    width: 100%;
    height: 10px;
  }
`;

export function BackgroundColourSwitch({ block, edit }: Props) {
  const ref = useRef<HTMLInputElement>(null!);

  if (!block || !edit) return null;

  const setStyle = SetStyle({ block, edit });

  return (
    <Container onClick={() => ref.current?.click()} className="unselectable">
      <Square colour={block.style.backgroundColor || 'white'} />
      <input
        ref={ref}
        type="color"
        value={block.style.backgroundColor || 'white'}
        onChange={e => setStyle({ backgroundColor: e.target.value })}
      />
    </Container>
  );
}
