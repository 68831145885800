import { Block } from 'interfaces/Blocks';
import { State } from 'interfaces/State';
import { createContext, useState } from 'react';

export declare namespace NSEditorContext {
  export interface Context {}

  interface Props {
    children: React.ReactNode;
  }
}

export const Context = createContext<NSEditorContext.Context>({
  blockState: [null, null!],
});

export function EditorContext({ children }: NSEditorContext.Props) {
  return <Context.Provider value={{}}>{children}</Context.Provider>;
}
