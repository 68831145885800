import { createContext, useCallback, useState } from 'react';

const { ceil, random } = Math;

export declare namespace NSGlobal {
  export interface Context {
    UIDs: number[];

    getUID: () => readonly [number, () => void];
  }

  interface Props {
    children: React.ReactNode;
  }
}

export const Context = createContext<NSGlobal.Context>({
  UIDs: null!,

  getUID: null!,
});

export function Global({ children }: NSGlobal.Props) {
  const [UIDs, setUIDs] = useState<number[]>([]);

  const getUID = useCallback((): readonly [number, () => void] => {
    let x = 0;
    while (!x || UIDs.includes(x)) x = ceil(random() * 100000000);

    setUIDs(current => [...current, x]);

    return [x, () => setUIDs(current => current.filter(y => y !== x))];
  }, []);

  return (
    <Context.Provider value={{ UIDs, getUID }}>{children}</Context.Provider>
  );
}
