import { Context as Blocks } from 'app/Blocks';
import { useExternalClick } from 'hooks';
import { Text as IText } from 'interfaces/Blocks';
import { useContext } from 'react';
import { Props } from '../Props';
import { ContentContainer } from '../styles';

export function Text({ block: b, editState: [editing, setEditing] }: Props) {
  const block = b as IText;

  const { editBlock } = useContext(Blocks);

  const dropOut = useExternalClick(() => setEditing(false));

  return (
    <ContentContainer ref={dropOut}>
      {editing || !block.value ? (
        <input
          autoFocus
          value={block.value}
          style={{ ...block.style }}
          onChange={e => {
            setEditing(true);
            editBlock({ ...block, value: e.target.value });
          }}
        />
      ) : (
        <p
          className="unselectable"
          onDoubleClick={() => setEditing(true)}
          style={block.style}
        >
          {block.value}
        </p>
      )}
    </ContentContainer>
  );
}
