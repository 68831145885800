import { Content, Popup as Element, TinyTriangle } from './styles';

type Props = {
  children: JSX.Element[];
  triangleless?: boolean;
};

export function Popup({ children, triangleless }: Props) {
  return (
    <Element triangleless={triangleless}>
      {!triangleless && <TinyTriangle />}
      <Content>{children}</Content>
    </Element>
  );
}
