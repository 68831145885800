import { Checkmark } from '@styled-icons/fluentui-system-regular';
import { CSSProperties, useContext } from 'react';
import { Context as SelectContext } from './SelectContext';
import { OptionContainer as Container } from './styles';

type Props = {
  value?: any;
  children:
    | string
    | JSX.Element
    | string[]
    | JSX.Element[]
    | (string | JSX.Element)[];
  style?: CSSProperties;
};

export function Option({ value, children, style }: Props) {
  const { onChange, value: selectedValue } = useContext(SelectContext);

  return (
    <Container
      style={style}
      onClick={() => onChange && onChange(value || children)}
    >
      {children}

      <div style={{ margin: '0 0 0 5px' }}>
        {value && value === selectedValue && <Checkmark size={20} />}
      </div>
    </Container>
  );
}
