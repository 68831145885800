export const theme = {
  colors: {
    background: '#FFFFFF',
    text: '#121214',
    placeholder: '#CBD5E0',

    primary: '#CFCFCF',
    secondary: '#4CBBF6',
    tertiary: '#F6B825',
  },

  contrast: {
    text: ['#121214', '#232325', '#535352', '#868684', '#ABABAA', '#ECECE1'],
    primary: {
      l7: '#FFFFFF',
      l6: '#EFEFEF',
      l5: '#CFCFCF',
      l4: '#BFBFBF',
      l3: '#AFAFAF',
      l2: '#9F9F9F',
      l1: '#8F8F8F',
      l0: '#7F7F7F',
      d1: '#6F6F6F',
      d2: '#5F5F5F',
      d3: '#4F4F4F',
      d4: '#3F3F3F',
      d5: '#2F2F2F',
      d6: '#1F1F1F',
      d7: '#0F0F0F',
    },
  },
};
