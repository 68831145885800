import React, { HTMLAttributes, useEffect, useId, useState } from 'react';
import { ChevronDown } from '@styled-icons/fluentui-system-regular';
import { State } from 'interfaces/State';

import { useEventListener, useExternalClick } from 'hooks';

import { Container } from './styles';
import { Popup } from './Popup/Popup';
import { SelectContext } from './SelectContext';

type Props = {
  children: JSX.Element[];
  placeholder?: string | JSX.Element;
  open: State<boolean>;
  value?: any;
  triangleless?: boolean;
  chevronless?: boolean;

  onChange?: (value: any) => void;
} & Omit<
  React.DetailedHTMLProps<HTMLAttributes<HTMLSelectElement>, HTMLSelectElement>,
  'value' | 'placeholder' | 'onChange'
>;

export function Select({
  children,
  placeholder,
  open: [open, setOpen],
  value,

  triangleless,
  chevronless,

  onChange,
  ...props
}: Props) {
  const id = useId();
  const ref = useExternalClick(() => setOpen(false));
  const fireSelectOpen = useEventListener('select-open', ({ detail }) => {
    if (detail.id !== id) setOpen(false);
  });

  const selection =
    value && children.find(x => x.props.value === value)?.props.children;

  useEffect(() => {
    if (open) fireSelectOpen({ id });
  }, [open]);

  return (
    <SelectContext onChange={onChange} value={value}>
      <Container
        id={`select-${id}`}
        onClick={(() => setOpen(!open)) as any}
        className="select unselectable"
        open={open}
        {...(props as any)}
        ref={ref}
      >
        <span>{selection || placeholder || 'Select'}</span>
        {!chevronless && <ChevronDown size={20} className="arrow" />}
        {open && <Popup triangleless={triangleless}>{children}</Popup>}
      </Container>
    </SelectContext>
  );
}
