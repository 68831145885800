import { Block } from 'interfaces/Blocks';
import { Text } from './Text';
import { TextField, SelectField, CheckboxField } from './Field';
import { ButtonField } from './Button';

export const components = {
  text: Text,
  'text-field': TextField,
  'button-field': ButtonField,
  'select-field': SelectField,
  'checkbox-field': CheckboxField,
} as unknown as {
  [name in Block['type']]: typeof Text;
};
