import { Params } from '../_Params';

export const ToggleItalic =
  ({ edit, block: { id, style } }: Params) =>
  () => {
    edit({
      id,
      style: {
        ...style,
        fontStyle: (style.fontStyle || '').includes('italic') ? '' : 'italic',
      },
    });
  };
