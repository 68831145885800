import { Context as Projects } from 'app/Projects';
import { Button, Form, Input } from 'components/Forms';
import { Context as Modal, WindowModal } from 'components/Overlay';
import { useStateForm } from 'hooks';
import { ProjectForm } from 'interfaces/Project';
import { useContext } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;

  min-width: 100vw;
  min-height: 100vh;

  overflow: hidden;

  z-index: 20;
`;

export const Bottom = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export function CreateProjectModal() {
  const { closeModal } = useContext(Modal);

  const {
    project: [, setProject],
    showProjectCreator: [open, setOpen],
    createProject,
  } = useContext(Projects);

  const [form, formHandler] = useStateForm<ProjectForm>({
    name: '',
    description: '',
  });

  const close = async () => {
    await closeModal();
    setOpen(false);
  };

  const submit = () => {
    try {
      setProject(createProject(form));
      close();
    } catch (err) {
      alert((err as Error).message);
    }
  };

  return (
    <Container onClick={close}>
      <WindowModal id="create-project" path="new">
        <Form onClick={e => e.stopPropagation()} onSubmit={submit}>
          <Input
            type="text"
            name="name"
            placeholder="Project name"
            value={form.name}
            onChange={formHandler}
            style={{ border: 'none', fontSize: '2em' }}
            autoFocus
          />

          <Input
            type="text"
            name="description"
            value={form.description}
            placeholder="Description"
            onChange={formHandler}
            maxLength={70}
          />

          <Bottom>
            <Button type="submit">Create project</Button>
          </Bottom>
        </Form>
      </WindowModal>
    </Container>
  );
}
