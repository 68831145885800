import { ToggleItalic } from '../../Functions/Text/ToggleItalic';
import { Props } from '../../props';
import { Button } from '../styles';

export function ItalicSwitch({ block, edit }: Props) {
  if (!block || !edit) return null;

  return (
    <Button
      onClick={ToggleItalic({ block, edit })}
      selected={block.style.fontStyle === 'italic'}
      type="button"
    >
      I
    </Button>
  );
}
