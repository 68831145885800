import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  bottom: 100px;
  right: 20px;
  width: 150px;
  height: 20px;

  opacity: 0.5;

  pointer-events: none;

  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  img {
    height: 50px;
    width: 50px;
  }

  p {
    font-size: 1em;
    display: flex;

    > * {
      margin: 0 5px;
    }
  }

  .double-lined {
    display: inline;
    text-decoration: overline underline;
  }
`;
