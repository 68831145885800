import { Select, Option } from 'components/Select';
import { useState } from 'react';
import { SetStyle } from '../Functions/SetStyle';
import { Props } from '../props';

export function PaddingSelector({ block, edit }: Props) {
  if (!block || !edit) return null;

  const fontSizeSelectorOpen = useState(false);

  return (
    <div style={{ margin: '0 10px 0 0' }}>
      <Select
        open={fontSizeSelectorOpen}
        placeholder="Padding"
        onChange={v =>
          SetStyle({ block, edit })({
            padding: Number(v),
          }) as any
        }
        value={Number(block.style.padding) || 0}
      >
        {Array(92)
          .fill(0)
          .map((x, y) => (
            <Option key={`padding-option${x + y}`} value={x + y}>
              {x + y}
            </Option>
          ))}
      </Select>
    </div>
  );
}
