import { Block } from 'interfaces/Blocks';

export const rebuild = (
  blocks: Block[],
  match: (block: Block) => boolean,
  transform: (block: Block) => Block,
): Block[] =>
  blocks.map(block =>
    match(block)
      ? transform({
          ...block,
          children: rebuild(block.children, match, transform),
        })
      : ({
          ...block,
          children: rebuild(block.children, match, transform),
        } as Block),
  );
