import { Params } from '../_Params';

export const ToggleUnderscore =
  ({ edit, block: { id, style } }: Params) =>
  () => {
    const r = String(style.textDecoration || '');
    edit({
      id,
      style: {
        ...style,
        textDecoration: r.includes('underline')
          ? r.replace('underline', '').trim()
          : `${r} underline`.trim(),
      },
    });
  };
