import styled from 'styled-components';
import PYYNE from 'assets/images/PYYNE-logo.png';
import React from 'react';

const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  margin: none !important;
  padding: 0;

  img {
    width: 100%;
    height: 100%;

    filter: drop-shadow(0px 0px 10px #24242466);
  }
`;

type Props = React.DetailedHTMLProps<
  React.ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>;

export function Logo(props: Props) {
  return (
    <Container>
      <img {...props} alt="PYYNE logo" src={PYYNE} />
    </Container>
  );
}
