import { Select, Option } from 'components/Select';
import { useState } from 'react';
import { SetStyle } from '../Functions/SetStyle';
import { Props } from '../props';

export function FontFamilySelector({ block, edit }: Props) {
  if (!block || !edit) return null;

  const openState = useState(false);

  return (
    <div style={{ margin: '0 10px 0 0' }}>
      <Select
        open={openState}
        value={block.style.fontFamily || "'Segoe UI'"}
        placeholder="Font"
        onChange={fontFamily =>
          SetStyle({ block, edit })({ fontFamily }) as any
        }
      >
        <Option style={{ fontFamily: 'Raleway' }} value="Raleway">
          Raleway
        </Option>
        <Option style={{ fontFamily: 'Arial' }} value="Arial">
          Arial
        </Option>
        <Option style={{ fontFamily: 'Verdana' }} value="Verdana">
          Verdana
        </Option>
        <Option style={{ fontFamily: 'Helvetica' }} value="Helvetica">
          Helvetica
        </Option>
        <Option style={{ fontFamily: 'Tahoma' }} value="Tahoma">
          Tahoma
        </Option>
        <Option
          style={{ fontFamily: 'Times New Roman' }}
          value="Times New Roman"
        >
          Times New Roman
        </Option>
        <Option style={{ fontFamily: 'Segoe UI' }} value="Segoe UI">
          Segoe UI
        </Option>
        <Option style={{ fontFamily: 'Georgia' }} value="Georgia">
          Georgia
        </Option>
        <Option style={{ fontFamily: 'Garamond' }} value="Garamond">
          Garamond
        </Option>
      </Select>
    </div>
  );
}
