import { Context as Projects } from 'app/Projects';
import { Select } from 'components/Select';
import { Option } from 'components/Select/Option';
import { useContext } from 'react';
import { Context as Main } from 'views/Main/MainContext';

export function ProjectSelector() {
  const { projectSelectorOpen } = useContext(Main);

  const {
    project: [project, setProject],
    projects,
  } = useContext(Projects);

  return (
    <Select
      open={projectSelectorOpen}
      onChange={(id: number) => setProject(projects.find(x => x.id === id)!)}
      value={project?.id}
    >
      {projects.map(project => (
        <Option key={project.name} value={project.id}>
          {project.name}
        </Option>
      ))}
    </Select>
  );
}
