import { Params } from '../_Params';

export const ToggleBold =
  ({ edit, block: { id, style } }: Params) =>
  () =>
    edit({
      id,
      style: {
        ...style,
        fontWeight: (style.fontWeight || 300) >= 900 ? 450 : 900,
      },
    });
