import { useContext } from 'react';

import { Context as ModalContext, NSModal } from '../ModalContext';

import { Modal } from '../Modal';
import { CustomOverlay } from '../styles';
import { Container } from './styles';

export function WindowModal({ children, ...props }: NSModal.Props) {
  const { modalClosing } = useContext(ModalContext);

  return (
    <Modal transitionDuration={800} {...props}>
      <CustomOverlay fadeOut={modalClosing}>
        <Container exiting={modalClosing}>{children}</Container>
      </CustomOverlay>
    </Modal>
  );
}
