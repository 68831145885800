import { stylesheet } from './Stylesheet';

type Style = string | TemplateStringsArray;

export const deviceWidthLimits = stylesheet`
    phone: 500px;
    desktop: 500px;
`;

export const Phone = (style: Style) => `
    @media (max-width: ${deviceWidthLimits.phone}) {
        ${style}
    }
`;

export const Tablet = (style: Style) => `
    @media (min-width: 500px) and (min-height: 600px) {
        ${style}
    }
`;

export const Desktop = (style: Style) => `
    @media (min-width: ${deviceWidthLimits.desktop}) {
        ${style}
    }
`;

export const Pointer = (style: Style) => `
    @media (any-hover) {
        &:hover {
            ${style}
        }
    }
`;

export const Touch = (style: Style) => `
    @media (hover: none) {
        &:active {
            ${style}
        }
    }
`;
