import { Params } from '../_Params';

export const SetFontSize =
  ({ edit, block: { id, style } }: Params) =>
  (fontSize: number) =>
    edit({
      id,
      style: {
        ...style,
        fontSize,
      },
    });
