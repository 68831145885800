import { useContext, useEffect, useMemo } from 'react';
import { Route, Routes, useLocation, useParams } from 'react-router-dom';

import { Context as ModalContext, NSModal } from './ModalContext';

type Props = NSModal.Props & {
  transitionDuration: number;
};

export function Modal({
  id,
  children,
  transitionDuration,
  ...props
}: Props): JSX.Element {
  const { pathname } = useLocation();
  const params = useParams();

  const {
    modal,
    navigate,
    modalClosing,
    setModal,
    setOverlay,
    setTransitionDuration,
  } = useContext(ModalContext);

  const active = useMemo(
    () =>
      modal === id ||
      pathname.split('/').at(-1) === props.path ||
      (props.path?.includes(':') &&
        !Number.isNaN(Number(params['*']?.split('/').at(-1)))),
    [modal, id, pathname, props.path],
  );

  useEffect(() => {
    if (!modal && props.open) setModal(id);
  }, [modal, props.open, id]);

  useEffect(() => {
    if (active) setTransitionDuration(transitionDuration);
  }, [active, transitionDuration]);

  useEffect(() => {
    if (active) setOverlay(props.overlay || false);
  }, [active, props.overlay]);

  useEffect(() => {
    const pathArray = pathname.split('/');

    if (modalClosing)
      navigate(pathArray.slice(0, pathArray.length - 1).join('/'));
  }, [props.path, modalClosing, navigate]);

  if (!active) return null!;

  if (props.path)
    return (
      <Routes>
        <Route path={props.path} element={children} />
      </Routes>
    );

  // eslint-disable-next-line
  return children as any;
}
