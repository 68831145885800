import styled from 'styled-components';

export const Container = styled.div``;

export const Button = styled.button<{ focused: boolean }>`
  margin: 0;

  text-align: center;

  color: ${({ theme }) => theme.contrast.primary.d5};

  border: none;
  background-color: ${({ theme }) => theme.colors.primary};
  padding: 5px;

  font-weight: 900;

  cursor: pointer;

  transition: all 0.15s ease-in-out;

  border-radius: 50px;

  &:hover {
    background-color: ${({ theme }) =>
      theme.colors.primary || theme.colors.background}cc;
  }

  &:active {
    transition: all 0s ease-in-out;
    background-color: ${({ theme }) =>
      theme.colors.primary || theme.colors.background}ff;
  }

  &:disabled {
    background-color: ${({ theme }) => theme.contrast.primary.d1};
    color: ${({ theme }) => theme.contrast.primary.d1};
    cursor: default;
  }

  ${({ focused }) =>
    focused &&
    `
    &:not(:active) {
      transform: scale(1.15, 1.15);
      text-shadow: 0px 0px 15px #00000024;

    }
  `}
`;
