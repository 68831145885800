import React, { DetailedHTMLProps, HTMLAttributes, useState } from 'react';

import { Container, Rectangle, Square, Offset } from './styles';

type Props = {
  children?: React.ReactNode;
  checked?: boolean;
  // eslint-disable-next-line no-unused-vars
  toggle?: (state: boolean) => void;
  disabled?: boolean;
  captionBelow?: boolean;
} & DetailedHTMLProps<HTMLAttributes<HTMLInputElement>, HTMLInputElement>;

export function Checkbox({
  children,
  checked,
  toggle,
  disabled,
  captionBelow,
  ...props
}: Props) {
  const [focused, setFocused] = useState(false);

  const handler = (e: any) => {
    const { keyCode } = e;
    if ((keyCode === 13 || keyCode === 32) && toggle) toggle(!checked);
  };

  return (
    <Container {...props} className="checkbox" focused={focused}>
      <input
        onFocus={() => (!disabled ? setFocused(true) : null)}
        onBlur={() => (!disabled ? setFocused(false) : null)}
        onKeyDown={!disabled ? handler : () => {}}
      />
      <Rectangle
        className="checkbox-container"
        onClick={() => (!disabled ? toggle!(!checked) : null)}
      >
        <Square className="checkbox-button" checked={checked} />
      </Rectangle>
      <Offset
        className="checkbox-offset"
        captionBelow={captionBelow}
        onClick={() => (!disabled ? toggle!(!checked) : null)}
      >
        {children}
      </Offset>
    </Container>
  );
}
