import React, { createContext, useCallback, useEffect, useState } from 'react';
import { To, useNavigate } from 'react-router-dom';
import { useTimedCallback } from 'hooks';

export declare namespace NSModal {
  type Props = {
    id: string;
    children?: React.ReactNode;

    open?: boolean;
    path?: string;
    overlay?: boolean;
  };

  export interface Context {
    modal: string;
    modalClosing: boolean;

    overlay: boolean;

    openModal: (id: string) => Promise<void>;
    closeModal: () => Promise<void>;
    toggleModal: (id: string) => Promise<void>;

    onClose: () => void;

    setModal: React.Dispatch<React.SetStateAction<string>>;
    setTransitionDuration: React.Dispatch<React.SetStateAction<number>>;
    setOverlay: React.Dispatch<React.SetStateAction<boolean>>;

    setOnClose: React.Dispatch<React.SetStateAction<() => void>>;

    navigate: (to: To) => Promise<void>;
  }

  interface ContextProps {
    children: React.ReactNode;
  }
}

export const Context = createContext<NSModal.Context>({
  modal: null!,
  modalClosing: false,

  overlay: false,

  openModal: null!,
  closeModal: null!,
  toggleModal: null!,

  onClose: null!,

  setModal: null!,
  setTransitionDuration: null!,
  setOverlay: null!,
  setOnClose: null!,

  navigate: null!,
});

export function ModalContext({ children }: NSModal.ContextProps) {
  const callNavigate = useNavigate();

  const [modal, setModal] = useState<string>(null!);
  const [modalClosing, setModalClosing] = useState(false);
  const [onClose, setOnClose] = useState<() => void>(null!);

  const [overlay, setOverlay] = useState(false);

  const [transitionDuration, setTransitionDuration] = useState(800);

  const openModal = useTimedCallback(
    (id: string) => setModal(id),
    transitionDuration,
  );

  const closeModal = useTimedCallback(
    () => setModalClosing(true),
    transitionDuration,
  );

  const toggleModal = useTimedCallback(
    (id: string) => {
      if (modal === id) closeModal();
      else openModal(id);
    },
    transitionDuration,
    [modal],
  );

  const navigate = useCallback(
    async (destination?: To) => {
      await closeModal();

      if (destination) callNavigate(destination, { replace: true });
      else callNavigate(-1);
    },
    [callNavigate],
  );

  useEffect(() => {
    if (modalClosing)
      setTimeout(() => {
        setModalClosing(false);
        setModal(null!);
      }, transitionDuration);
  }, [transitionDuration, modalClosing]);

  return (
    <Context.Provider
      value={{
        modal,
        modalClosing,
        overlay,
        onClose,
        setModal,
        setTransitionDuration,
        setOverlay,
        setOnClose,
        openModal,
        closeModal,
        toggleModal,
        navigate,
      }}
    >
      {children}
    </Context.Provider>
  );
}
