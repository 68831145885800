import styled from 'styled-components';
import { footerStyles } from './constants';

export const Container = styled.footer`
  width: 100vw;
  height: ${footerStyles.height};

  z-index: 10;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  background: linear-gradient(
    180deg,
    ${({ theme }) =>
      `${theme.contrast.primary.d1}, ${theme.contrast.primary.d2}`}
  );
`;
