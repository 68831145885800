import { State } from 'interfaces/State';
import { createContext, useState } from 'react';

export declare namespace NSMainContext {
  export interface Context {
    projectSelectorOpen: State<boolean>;
  }

  interface Props {
    children: React.ReactNode;
  }
}

export const Context = createContext<NSMainContext.Context>({
  projectSelectorOpen: [false, null!],
});

export function MainContext({ children }: NSMainContext.Props) {
  const projectSelectorOpen = useState(false);

  return (
    <Context.Provider
      value={{
        projectSelectorOpen,
      }}
    >
      {children}
    </Context.Provider>
  );
}
