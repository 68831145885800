import { useContext } from 'react';
import { Context as Layout } from 'app/Layout';

import { Aside } from '../Aside';
import { WorkArea } from './styles';

export function Navigator() {
  const { navigatorOpen } = useContext(Layout);

  return (
    <Aside openState={navigatorOpen} side="left">
      <WorkArea />
    </Aside>
  );
}
