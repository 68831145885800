import styled from 'styled-components';
import { stylesheet } from 'styles';
import { Phone } from 'styles/media';

const constants = stylesheet`
  width: 300px;
`;

export const Container = styled.aside<{
  open: boolean;
  side: 'left' | 'right';
}>`
  ${constants.toString()}
  height: calc(100%);

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  transition: all 0.3s ease-in-out;

  z-index: 10;

  ${({ side }) =>
    side === 'right' &&
    `
    flex-direction: row-reverse;
  `}

  position: relative;

  background: linear-gradient(
    ${({ side }) => (side === 'left' ? '90deg' : '270deg')},
    ${({
      theme: {
        contrast: { primary },
      },
    }) => `${primary.l6}ec, ${primary.l7}ec`}
  );

  ${Phone`
    display: none;
  `}

  ${({ open, side }) =>
    !open
      ? side === 'left'
        ? `
      left: -${constants.width};
    `
        : `
      right: -${constants.width};
    `
      : ''}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  height: 100%;
`;
