import styled from 'styled-components';

export const Container = styled.div<{ pulse?: number }>`
  width: 100px;
  height: 100px;

  display: flex;
  justify-content: space-evenly;
  align-items: center;

  animation: pulse ${({ pulse }) => pulse}s infinite linear;
`;

export const Square = styled.div<{ pulse?: number }>`
  width: 15px;
  height: 15px;

  animation: pulse ${({ pulse }) => pulse}s infinite,
    float ${({ pulse }) => 3 - pulse!}s infinite linear;

  background-color: ${({ theme }) => theme.colors.text};

  border-radius: 50%;
`;
