import { Projects } from 'app/Projects';
import { Blocks } from 'app/Blocks';

import { Toolbar, Navigator, Footer, FormRenderer, Editor } from 'components';
import { EditorContext } from 'components/Aside/Editor';
import { ModalContext } from 'components/Overlay';

import { Container, Content, View } from './styles';
import { MainContext } from './MainContext';

export function Main() {
  return (
    <ModalContext>
      <MainContext>
        <Projects>
          <Blocks>
            <EditorContext>
              <Container>
                <Toolbar />
                <View>
                  <Navigator />
                  <Content>
                    <FormRenderer />
                  </Content>
                  <Editor />
                </View>
                <Footer />
              </Container>
            </EditorContext>
          </Blocks>
        </Projects>
      </MainContext>
    </ModalContext>
  );
}
