import { BorderSide } from 'interfaces/CSSBorders';
import { Params } from '../_Params';

export const ToggleBorder =
  ({ edit, block: { id, style } }: Params, side: BorderSide) =>
  () => {
    const r = `border-${side}`;

    edit({
      id,
      style: {
        ...style,
        [r]: !(style as any)[r] ? '1px solid grey' : undefined,
      },
    });
  };
