import styled from 'styled-components';
import { Overlay } from '..';

export const CustomOverlay = styled(Overlay)<{ fadeOut: boolean }>`
  ${({ fadeOut, theme }) =>
    fadeOut &&
    `
  animation: none;
  background-color: ${theme.contrast.primary.d5}00 !important;
`}
`;
